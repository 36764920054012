import AuthService, {
  AuthCredentialsDto,
  PersonaCreateDto
} from "@/api/AuthService";
import {
  VuexModule,
  Module,
  Mutation,
  Action,
  getModule
} from "vuex-module-decorators";

import store from "../index";

const userData = localStorage.getItem("user");
const user = userData ? JSON.parse(userData) : null;

@Module({
  namespaced: true,
  name: "AuthStore",
  store: store,
  dynamic: true
})
class AuthStore extends VuexModule {
  public loggedIn = user ? true : false;
  public user = user ? user : null;

  @Mutation
  loginSuccess(user: unknown) {
    this.loggedIn = true;
    this.user = user;
  }

  @Mutation
  loginFailure() {
    this.loggedIn = false;
    this.user = null;
  }

  @Mutation
  logoutSuccess() {
    this.loggedIn = false;
    this.user = null;
  }

  @Action
  public login(authCredentialsDto: AuthCredentialsDto) {
    console.log(authCredentialsDto);
    return AuthService.login(authCredentialsDto).then(
      user => {
        this.context.commit("loginSuccess", user);
        return Promise.resolve(user);
      },
      error => {
        this.context.commit("loginFailure");
        return Promise.reject(error);
      }
    );
  }

  @Action
  public register(createDto: PersonaCreateDto) {
    console.log(createDto);
    return AuthService.signup(createDto).then(
      response => {
        return Promise.resolve(response.data);
      },
      error => {
        return Promise.reject(error);
      }
    );
  }

  @Action
  public logout() {
    AuthService.logout();
    this.context.commit("logoutSuccess");
  }

  get getLoggedIn(): boolean {
    return this.loggedIn;
  }

  get getRole(): string {
    return this.user.role; // devuelve un string el numero del rol
  }

  get getRoleDisplay(): string {
    return this.user.roleDisplay;
  }

  get getPersonaEdad(): number {
    return this.user.persona.edad;
  }

  get getPersonaSexo(): string {
    return this.user.persona.sexo;
  }

  get getCarnetConadis(): string {
    return this.user.persona.carnetConadis;
  }

  get getPersonaNombre(): string {
    return this.user.persona.nombre;
  }
  get getPersonaID(): number {
    return this.user.persona.id;
  }
}

export default getModule(AuthStore);
