import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import Fragment from "vue-fragment";
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import "selectize/dist/css/selectize.default.css"; // This is required, we use the same css as selectize.js
import * as VSelectize from "@isneezy/vue-selectize";
import VueMeta from "vue-meta";

Vue.component("v-selectize", VSelectize);

Vue.use(VueMeta);
//import DatetimePicker from "vuetify-datetime-picker";
//Vue.use(DatetimePicker);
Vue.use(Fragment.Plugin);
Vue.use(VueSweetalert2);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount("#app");
