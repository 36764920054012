//import axios ,{ AxiosRequestConfig } from 'axios';
import router from "@/router";
import axios from "axios";

const httpClient = axios.create({
  baseURL: process.env.VUE_APP_API
});

const getAuthToken = () => localStorage.getItem("jwt_token_omg");

const authInterceptor = (config: any) => {
  config.headers["Authorization"] = `Bearer ${getAuthToken()}`;
  return config;
};

httpClient.interceptors.request.use(authInterceptor);

httpClient.interceptors.response.use(
  config => config,
  async error => {
    if (!error.response) {
      return new Promise((_, reject) => {
        reject(error);
      });
    }

    if (error.config.url === "auth/refresh") {
      // logout
      localStorage.removeItem("jwt_token_omg");
      localStorage.removeItem("jwt_refresh_omg");
      localStorage.removeItem("user");
      router.push("/login");
    }

    if (error.response.status === 403 || error.response.status === 401) {
      const token = localStorage.getItem("jwt_refresh_omg");
      const dto = { refreshToken: token };
      const res = await httpClient.post("auth/refresh", dto);
      const data = res.data;
      localStorage.setItem("jwt_token_omg", data.accessToken);
      // resend original request
      return await httpClient.request(error.config);
    }

    return new Promise((_, reject) => {
      reject(error);
    });
  }
);

export default httpClient;
