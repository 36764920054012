






































































































import Vue from "vue";
import axios from "axios";
import AuthStore from "@/store/modules/AuthStore";
import RoleService, { GetRoleDto } from "@/api/RoleService";
//import { Fragment } from "vue-fragment";

export default Vue.extend({
  name: "App",

  components: {
    //HelloWorld
  },

  data: () => ({
    papeletasPendientes: 0,
    titulo: null,
    url: null,
    drawer: true,
    items: [
      {
        title: "Dashboard",
        icon: "mdi-home",
        url: "/admin"
        //permission: { action: "", subject: "" }
      },
      /*
      { title: "Datos Base", icon: "mdi-database", url: "/admin/datos-base" },
      */
      {
        title: "Comisiones",
        icon: "mdi-cash-multiple",
        url: "/admin/comisiones",
        permission: {
          action: "manage",
          subject: "comision"
        }
      },

      {
        title: "Comisiones Salud",
        icon: "mdi-database",
        url: "/admin/comisiones-salud",
        permission: {
          action: "manage",
          subject: "comision"
        }
      },
      /*
      {
        title: "Comisiones Reporte",
        icon: "mdi-database",
        url: "/admin/comisiones-reporte",
        permission: {
          action: "manage",
          subject: "comision"
        }
      },
      */
      {
        title: "Cobros BMI",
        icon: "mdi-cash-multiple",
        url: "/admin/cobros-bmi",
        permission: {
          action: "manage",
          subject: "cobros-bmi"
        }
      },
      {
        title: "Agentes Base",
        icon: "mdi-database",
        url: "/admin/agentes-base",
        permission: {
          action: "manage",
          subject: "agente"
        }
      },
      {
        title: "Remisoria",
        permission: {
          action: "manage",
          subject: "remisoria"
        },
        items: [
          {
            title: "Renovación",
            icon: "mdi-cash-multiple",
            url: "/admin/remisoria-renovacion",
            permission: {
              action: "manage",
              subject: "remisoria"
            }
          },
          {
            title: "Remisoria",
            icon: "mdi-cash-multiple",
            url: "/admin/remisoria",
            permission: {
              action: "manage",
              subject: "remisoria"
            }
          },
          {
            title: "Cierre Anual",
            icon: "mdi-cash-multiple",
            url: "/admin/remisoria-cierre-anual",
            permission: {
              action: "manage",
              subject: "remisoria"
            }
          },
          {
            title: "Unión",
            icon: "mdi-cash-multiple",
            url: "/admin/remisoria-union",
            permission: {
              action: "manage",
              subject: "remisoria"
            }
          }
        ]
      },
      {
        title: "Remisoria Comisión",
        icon: "mdi-cash-multiple",
        url: "/admin/remisoria-comision",
        permission: {
          action: "manage",
          subject: "remisoria.comision"
        }
      },
      {
        title: "Cobro Poliza",
        icon: "mdi-text-box-search",
        url: "/admin/poliza",
        permission: {
          action: "manage",
          subject: "remisoria"
        }
      },
      {
        title: "Pago",
        icon: "mdi-cash-multiple",
        url: "/admin/pagos",
        permission: {
          action: "manage",
          subject: "pago"
        }
      },
      {
        title: "Reporte Cobranzas",
        icon: "mdi-cash-multiple",
        url: "/admin/reporte-cobranzas",
        permission: {
          action: "manage",
          subject: "reporte-cobranza"
        }
      },
      {
        title: "Consolidado",
        icon: "mdi-cash-multiple",
        url: "/admin/pagos/reporte",
        permission: {
          action: "manage",
          subject: "pago"
        }
      },
      {
        title: "Plan",
        icon: "mdi-cash-multiple",
        url: "/admin/planes",
        permission: {
          action: "manage",
          subject: "plan"
        }
      },
      {
        title: "Compañias",
        icon: "mdi-cash-multiple",
        url: "/admin/companias",
        permission: {
          action: "manage",
          subject: "compañia"
        }
      },

      {
        title: "Auth",
        permission: {
          action: "manage",
          subject: "user"
        },
        items: [
          {
            title: "Usuarios",
            icon: "mdi-account-group",
            url: "/admin/usuarios"
          },
          {
            title: "Roles",
            icon: "mdi-account-group",
            url: "/admin/roles"
          }
        ]
      }
    ],
    // nuevos variables
    userRole: { name: "Rol", permissions: [] as any[] } as GetRoleDto
  }),
  mounted() {
    console.log(`La api es: ${process.env.VUE_APP_API}`);
    this.url = process.env.VUE_APP_API;
    axios
      .get(process.env.VUE_APP_API + "/")
      .then(response => (this.titulo = response.data));
    this.getUserRole();
  },
  methods: {
    cerrarSesion() {
      AuthStore.logout();
      this.$router.push("/").catch(() => {
        console.log("Ruta activa");
      });
    },
    getUserRole() {
      RoleService.getUserRole().then(response => {
        this.userRole = response;
      });
    },
    haveRole(permission: any) {
      if (!permission) {
        return true;
      }
      for (const x of this.userRole.permissions) {
        if (permission.subject === x.subject) {
          return true;
        }
      }
      return false;
      /*
      if (this.userRole.permissions.indexOf(permission) >= 0) {
        return true;
      } else {
        return false;
      }
      */
    }
  },
  computed: {
    getRoleDisplay() {
      return AuthStore.getRoleDisplay;
    }
  }
});
