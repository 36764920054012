






import Vue from "vue";
import axios from "axios";
//import HelloWorld from "./components/HelloWorld.vue";

export default Vue.extend({
  name: "App",

  metaInfo: {
    title: "OMG"
  },

  components: {
    //HelloWorld
  },

  data: () => ({
    titulo: null,
    url: null,
    drawer: true,
    items: [
      { title: "Dashboard", icon: "mdi-home", url: "/" },
      { title: "Datos Base", icon: "mdi-database", url: "/datos-base" },
      { title: "Comisiones", icon: "mdi-cash-multiple", url: "/comisiones" },
      { title: "Cobros BMI", icon: "mdi-cash-multiple", url: "/cobros-bmi" },
      { title: "Agentes Base", icon: "mdi-database", url: "/agentes-base" },
      {
        title: "Comisiones Salud",
        icon: "mdi-database",
        url: "/comisiones-salud"
      },
      {
        title: "Comisiones Reporte",
        icon: "mdi-database",
        url: "/comisiones-reporte"
      },
      { title: "Remisoria", icon: "mdi-cash-multiple", url: "/remisoria" },
      { title: "Pagos", icon: "mdi-cash-multiple", url: "/pagos" }
      /*
      { title: "Catalogos", icon: "mdi-view-dashboard", url: "/" },
      { title: "Transacciones", icon: "mdi-cash-multiple", url: "/" },
      { title: "Reportes", icon: "mdi-gavel", url: "/" },
      { title: "Accesos", icon: "mdi-account-box", url: "/" }
      */
    ]
    //
  }),
  mounted() {
    console.log(`La api es: ${process.env.VUE_APP_API}`);
    this.url = process.env.VUE_APP_API;
    axios
      .get(process.env.VUE_APP_API)
      .then(response => (this.titulo = response.data));
  }
});
