import httpClient from "./http-client";

const ENDPOINT = "/auth";

export interface AuthCredentialsDto {
  username: string;
  password: string;
}

export interface PersonaCreateDto {
  correo: string;

  //datos de la persona
  tipoIdentificacion: string;

  identificacion: string;

  nombre: string;

  apellido: string;

  sexo: string;

  telefono: string;

  fechaNacimiento: string;

  //dispacidad: boolean;
  carnetConadis?: string;
}

class AuthService {
  //post
  login(authCredentialsDto: AuthCredentialsDto) {
    return httpClient
      .post(ENDPOINT + "/signin", authCredentialsDto)
      .then(response => {
        if (response.data.accessToken) {
          localStorage.setItem("jwt_token_omg", response.data.accessToken);
          localStorage.setItem("jwt_refresh_omg", response.data.refreshToken);
          localStorage.setItem("user", JSON.stringify(response.data));
        }
        return response.data;
      });
  }

  logout() {
    localStorage.removeItem("jwt_token_omg");
    localStorage.removeItem("jwt_refresh_omg");
    localStorage.removeItem("user");
  }

  signup(personaCreateDto: PersonaCreateDto) {
    return httpClient.post(ENDPOINT + "/signup", personaCreateDto);
  }

  resetPassword(dto: { username: string }) {
    return httpClient.post(`${ENDPOINT}/reset-password`, dto);
  }

  updatePassword(dto: { password: string }) {
    return httpClient.put(`${ENDPOINT}/update`, dto);
  }
}
export default new AuthService();
