import httpClient from "./http-client";

const ENDPOINT = "/role";

export interface GetRoleDto {
  id: number;
  name: string;
  permissions: any[];
}

export interface CreateRoleDto {
  id?: number;
  name: string;
  permissions: any[];
}

class RoleService {
  // otras
  getRoles(): Promise<GetRoleDto[]> {
    return httpClient.get(`${ENDPOINT}`).then(response => {
      return response.data;
    });
  }

  getUserRole(): Promise<GetRoleDto> {
    return httpClient.get(`${ENDPOINT}/me`).then(response => {
      return response.data;
    });
  }

  createRole(dto: CreateRoleDto) {
    return httpClient.post(ENDPOINT, dto);
  }
}
export default new RoleService();
