import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Home from "../views/Home.vue";
import AuthStore from "@/store/modules/AuthStore";
import Admin from "../views/admin/Main.vue";
import Web from "../views/web/Main.vue";
import Parent from "../views/admin/Parent.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/admin",
    component: Admin,
    beforeEnter: (to, from, next) => {
      if (AuthStore.getLoggedIn) {
        console.log("works");
        next();
      } else {
        console.log("not works");
        next("/");
      }
    },
    children: [
      {
        path: "",
        name: "Home",
        component: Home
      },
      {
        path: "datos-base",
        name: "DatosBase",
        component: () =>
          import(/* webpackChunkName: "datos-base" */ "../views/DatosBase.vue")
      },
      {
        path: "comisiones",
        name: "Comisiones",
        component: () =>
          import(/* webpackChunkName: "comisiones" */ "../views/Comisiones.vue")
      },
      {
        path: "cobros-bmi",
        name: "CobrosBMI",
        component: () =>
          import(/* webpackChunkName: "cobros-bmi" */ "../views/CobrosBMI.vue")
      },
      {
        path: "agentes-base",
        name: "AgentesBase",
        component: () =>
          import(
            /* webpackChunkName: "agentes-base" */ "../views/AgentesBase.vue"
          )
      },
      {
        path: "comisiones-salud",
        name: "ComisionesSalud",
        component: () =>
          import(
            /* webpackChunkName: "comisiones-salud" */ "../views/ComisionesSalud.vue"
          )
      },
      {
        path: "comisiones-reporte",
        name: "ComisionesReporte",
        component: () =>
          import(
            /* webpackChunkName: "comisiones-salud" */ "../views/ComisionesReporte.vue"
          )
      },
      {
        path: "remisoria-renovacion",
        name: "Remisoria-Renovacion",
        component: () =>
          import(
            /* webpackChunkName: "remisoria-renovacion" */ "../views/RemisoriaRenovacion.vue"
          )
      },
      {
        path: "remisoria",
        name: "Remisoria",
        component: () =>
          import(/* webpackChunkName: "remisoria" */ "../views/Remisoria.vue")
      },
      {
        path: "remisoria-cierre-anual",
        name: "Remisoria-Cierre-Anual",
        component: () =>
          import(
            /* webpackChunkName: "remisoria" */ "../views/RemisoriaCierreAnual.vue"
          )
      },
      {
        path: "remisoria-union",
        name: "Remisoria-Union",
        component: () =>
          import(
            /* webpackChunkName: "remisoria" */ "../views/RemisoriaUnion.vue"
          )
      },
      {
        path: "remisoria-comision",
        name: "Remisoria-Comision",
        component: () =>
          import(
            /* webpackChunkName: "remisoria-comision" */ "../views/RemisoriaComision.vue"
          )
      },
      {
        path: "poliza",
        component: Parent,
        children: [
          {
            path: "",
            name: "poliza",
            component: () =>
              import(
                /* webpackChunkName: "poliza" */ "../views/admin/poliza/Polizas.vue"
              )
          },
          {
            path: ":id",
            component: Parent,
            children: [
              {
                path: "dato",
                component: () =>
                  import(
                    /* webpackChunkName: "poliza-id" */ "../views/admin/poliza/Poliza.vue"
                  )
              },
              {
                path: "cobro",
                component: () =>
                  import(
                    /* webpackChunkName: "poliza-id-cobro" */ "../views/admin/poliza/cobro/Cobros.vue"
                  )
              }
            ]
          }
        ]
      },
      {
        path: "pagos",
        name: "Pagos",
        component: () =>
          import(/* webpackChunkName: "remisoria" */ "../views/Pagos.vue")
      },
      {
        path: "reporte-cobranzas",
        name: "reporte-cobranzas",
        component: () =>
          import(
            /* webpackChunkName: "remisoria" */ "../views/ReporteCobranza.vue"
          )
      },
      {
        path: "pagos/reporte",
        name: "pagos-reporte",
        component: () =>
          import(
            /* webpackChunkName: "remisoria" */ "../views/PagosReporte.vue"
          )
      },
      {
        path: "planes",
        name: "planes",
        component: () =>
          import(
            /* webpackChunkName: "planes" */ "../views/admin/plan/Planes.vue"
          )
      },
      {
        path: "companias",
        name: "companias",
        component: () =>
          import(
            /* webpackChunkName: "planes" */ "../views/admin/compañia/Compañias.vue"
          )
      },
      {
        path: "usuarios",
        name: "usuarios",
        component: () =>
          import(/* webpackChunkName: "usuarios" */ "../views/Usuario.vue")
      },
      {
        path: "roles",
        name: "roles",
        component: () =>
          import(/* webpackChunkName: "usuarios" */ "../views/Rol.vue")
      }
    ]
  },
  {
    path: "/",
    component: Web,
    children: [
      {
        path: "",
        name: "Home",
        component: Home
      },
      {
        path: "login",
        name: "Login",
        component: () =>
          import(/* webpackChunkName: "remisoria" */ "../views/Login.vue")
      },
      {
        path: "*",
        name: "NotFound",
        component: () =>
          import(/* webpackChunkName: "notfound" */ "../views/NotFound.vue")
      }
    ]
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
